import { useEffect } from 'react';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { Box, Divider, Grid, Stack } from '@mui/material';
import useGetMany from '../../../../../../utils/hooks/crud-hooks/useGetMany';
import CardListPagination from '../../../components/CardListPagination';
import useQueryState from '../../../../../../utils/hooks/useQueryState';
import BlogCard from '../../../../Blog/components/BlogCard';
import BlogArchiveFilters from './components/BlogArchiveFilters';
import LoadingIndicator from '../../../../../../components/loading-indicator/LoadingIndicator';
import CustomNoRowsOverlay from '../../../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';

const BlogArchiveGrid = () => {
  const [queryState, , , searchParams] = useQueryState({ defaultValue: { limit: 12, offset: 0 } });

  const queryBuilder = () => {
    const params = new URLSearchParams(searchParams);
    params.set('limit', queryState.limit ?? '12');
    params.set('offset', queryState.offset ?? '0');
    params.set('orderBy', queryState.orderBy ?? '-created');
    // params.set('role', 'seller_service');
    params.set('status', 'published');
    // params.set('visibility', 'true');

    return params.toString();
  };

  const {
    data: response,
    refetch,
    isLoading,
  } = useGetMany<BlogDto>({
    resource: `/blogs?${queryBuilder()}`,
  });

  const blogs = response?.data.results;
  const count = response?.data.count;

  useEffect(() => {
    refetch();
  }, [queryState.limit, queryState.offset, refetch]);

  return (
    <Box sx={{ backgroundColor: 'transparent' }}>
      <Stack overflow="hidden" maxWidth="100%" alignSelf="center">
        <BlogArchiveFilters />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Grid
            container
            spacing={2}
            rowSpacing={3}
            pt={0}
            mt={-1.5}
            gridAutoRows="max-content"
            sx={{ background: 'transparent' }}
          >
            {blogs?.map((item: any, index) => (
              <Grid
                key={item?.id}
                item
                xs={12}
                sm={6}
                md={6}
                bg={4}
                lg={4}
                xl={4}
                gridAutoRows="max-content"
              >
                <BlogCard blogData={item} />
              </Grid>
            ))}
          </Grid>
        )}
        <Divider
          sx={{
            mt: 6,
          }}
        />
        <CardListPagination count={count} />
      </Stack>
    </Box>
  );
};

export default BlogArchiveGrid;
