import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProjectSectionSmall from '../../../assets/img/public-pages/build-your-projects-small.png';
import ProjectSectionBig from '../../../assets/img/public-pages/build-your-projects-big.png';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/project-icons/ArrowRightIcon.svg';
import CustomButton from '../../../components/button/CustomButton';
import theme from '../../../theme';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

type OfferProps = {
  title: string;
  content: string;
  buttonTitle: string;
  link: string;
};

const offers: OfferProps[] = [
  {
    title: 'Plan your project',
    content: 'Post your project request and compare pitches from vetted sellers.',
    buttonTitle: 'post a project',
    link: 'how-to-hire/timed-projects',
  },
  {
    title: 'Seek expert support',
    content: 'Work with a Spona advisor to plan your project and shortlist best-fit sellers.',
    buttonTitle: 'spona Connect',
    link: 'how-to-hire/spona-connect',
  },
  {
    title: 'Explore your options',
    content: 'Learn more about how to hire for your digital project, or talk to us for support.',
    buttonTitle: 'how to hire',
    link: 'how-to-hire',
  },
];

const BuildProjectSection = () => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Stack
      position="relative"
      alignItems="center"
      sx={{
        height: '50rem',
        mb: { xs: 34, sm: 20, bg: 12, lg: 0 },
        ...LAYOUT_DEFAULTS,
        mt: { xs: 10, md: 6 },
      }}
    >
      <Box
        title="A person working on a laptop by the desk"
        sx={{
          position: 'relative',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          height: '70%',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundImage: {
            xs: `url(${getPublicImageUrl(EnumPublicPagesImage.BuildYourProjectSmall)})`,
            md: `url(${getPublicImageUrl(EnumPublicPagesImage.BuildYourProjectBig)})`,
          },
          borderRadius: '20px',
        }}
      >
        {/* Build Your Projects text & Get everything you need on one platform text */}
        <Stack
          height={{ xs: '85%', lg: 'fit-content' }}
          sx={
            isLessThanLarge
              ? {
                  zIndex: 2,
                  justifyContent: 'center',
                  pb: 2,
                  px: '5%',
                }
              : {
                  position: 'absolute',
                  bottom: '20%',
                  zIndex: 2,
                  left: '5%',
                }
          }
          spacing={1}
        >
          <Typography variant="publicHeading3" color="common.white" sx={{ maxWidth: '65%' }}>
            Build Your Projects
          </Typography>

          <Typography variant="publicBody3Regular" color="common.white">
            Find a digital service seller that best fits your project requirements
          </Typography>
        </Stack>
      </Box>

      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        spacing={2}
        width={{ xs: '100%', lg: '90%' }}
        height={{ xs: '8%', sm: '32%', bg: '40%', lg: '24%' }}
        sx={{ position: 'absolute', bottom: { xs: 20, md: 130 } }}
      >
        {offers.map((offer) => (
          <BuildBox
            title={offer.title}
            buttonTitle={offer.buttonTitle}
            content={offer.content}
            link={offer.link}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default BuildProjectSection;

const BuildBox = ({ title, buttonTitle, content, link }: OfferProps) => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={3}
      alignItems="start"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'primaryLight.500',
        zIndex: 2,
        p: 3,
        borderRadius: '10px',
        height: { xs: 'auto', sm: '100%' },
        width: '90%',
        boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
      }}
    >
      <Stack spacing={1}>
        <Typography variant="publicHeading5" color="common.white" fontWeight="400">
          {title}
        </Typography>

        <Typography variant="publicBody4Regular" color="common.white">
          {content}
        </Typography>
      </Stack>

      <CustomButton
        variant="primaryText"
        onClick={() => navigate(link)}
        endIcon={<ArrowRightIcon fill="#fff" />}
        // agreed with Arneo and Kat to increase this font size to 16px
        sx={{ color: 'white', fontSize: '1rem', fontFamily: 'Gellix-Regular' }}
      >
        {buttonTitle}
      </CustomButton>
    </Stack>
  );
};
