export enum EnumPublicPagesImage {
  AboutOffice = 'about_office',
  AbaoutUsHero = 'about-us-hero',
  AccessAtAnyTimeBuyer = 'access-at-any-time-buyer',
  AccessAtAnyTimeSeller = 'access-at-any-time-seller',
  AccessibleSupportBuyer = 'accessible-support-buyer',
  AccessibleSupportSeller = 'accessible-support-seller',
  ArchiveHero = 'archive-hero',
  AutomaticNotificationsBuyer = 'automatic-notifications-buyer',

  BuildYourProjectBig = 'build_your_project_big',
  BuildYourProjectSmall = 'build_your_project_small',

  CaseStudyArchiveHero = 'case-study-archive-hero',
  CaseStudyArchiveMacbook = 'case-study-archive-macbook',
  ClearCommunicationBuyer = 'clear-communication-buyer',
  ClearCommunicationSeller = 'clear-communication-seller',
  Communication = 'communication',
  CompleteMilestonesSeller = 'complete-milestones-seller',
  ConfirmAccountBuyer = 'confirm-account-buyer',
  ConfirmAccountBuyerxl = 'confirm-account-buyerxl',
  ConfirmAccountSeller = 'confirm-account-seller',
  ConfirmAccountSellerxl = 'confirm-account-sellerxl',
  ConsolidateYourProjectsBuyer = 'consolidate-your-projects-buyer',
  ConsolidateYourProjectsSeller = 'consolidate-your-projects-seller',
  CreateProfile = 'create-profile',
  CreateInRealTimeBuyer = 'create-in-real-time-buyer',
  CreateInRealTimeSeller = 'create-in-real-time-seller',

  DoItYourself = 'do-it-yourself',
  DigitalContract = 'digital-contract',
  Dublin = 'dublin',
  DublinMobile = 'dublin-mobile',

  EasyResourcePlanningSeller = 'easy-resource-planning-seller',
  EliminateLegalFeesBuyer = 'eliminate-legal-fees-buyer',
  EliminateLegalFeesSeller = 'eliminate-legal-fees-seller',

  GetInsightsBuyer = 'get-insights-buyer',
  GetInsightsSeller = 'get-insights-seller',
  GetFullControlBuyer = 'get-full-control-buyer',
  GridForSellers = 'grid-for-sellers',
  GridProjectBenefits = 'grid-project-benefits',

  HireSeller = 'hire-seller',
  HowToHireHero = 'how-to-hire-hero',
  HowToFindWorkHero = 'how-to-find-work-hero',
  Hungary = 'hungary',
  HungaryMobile = 'hungary-mobile',

  KeepInTouchBuyer = 'keep_in_touch_buyer',
  KeepInTouchSeller = 'keep_in_touch_seller',

  Laptop = 'laptop',
  LetSponaHandleIt = 'let-spona-handle-it',
  LetSponaHandleItHero = 'let-spona-handle-it-hero',
  LetSponaHandleItSmall = 'let-spona-handle-it-small',
  ListYourRequirements = 'list-your-requirements',

  MacbookSellerArchive = 'macbook-seller-archive',
  MakeDataDrivenDecisionsBuyer = 'make-data-driven-decisions-buyer',
  MakeDataDrivenDecisionsSeller = 'make-data-driven-decisions-seller',
  ManageMilestonesAndTasksBuyer = 'manage_milestones_and_tasks_buyer',
  ManageMilestonesAndTasksSeller = 'manage_milestones_and_tasks_seller',
  MilestonesAndTasksHero = 'milestones-and-tasks-hero',
  MonitorYourKPIs = 'monitor-your-kpis',

  NewPasswordChangeSection = 'new-password-change-section',

  Office = 'office',
  Office2 = 'office2',
  Office3 = 'office3',
  Office4 = 'office4',
  Office5 = 'office5',
  OnePlatformBuyer = 'one-platform-buyer',
  OnePlatformSeller = 'one-platform-seller',
  OurJourneyCarousel = 'our-journey-carousel',
  OurJourneyCarousel2 = 'our-journey-carousel2',
  OurJourneyCarousel3 = 'our-journey-carousel3',
  OurJourneyCarousel4 = 'our-journey-carousel4',
  OurJourneyCarousel5 = 'our-journey-carousel5',
  OurJourneyCarousel6 = 'our-journey-carousel6',
  OurJourneyCarousel7 = 'our-journey-carousel7',
  OurJourneyCarousel8 = 'our-journey-carousel8',
  OurJourneyCarouselMobile = 'our-journey-carousel-mobile',
  OurJourneyCarouselMobile2 = 'our-journey-carousel-mobile2',
  OurJourneyCarouselMobile3 = 'our-journey-carousel-mobile3',
  OurJourneyCarouselMobile4 = 'our-journey-carousel-mobile4',
  OurJourneyCarouselMobile5 = 'our-journey-carousel-mobile5',
  OurJourneyCarouselMobile6 = 'our-journey-carousel-mobile6',
  OurJourneyCarouselMobile7 = 'our-journey-carousel-mobile7',
  OurJourneyCarouselMobile8 = 'our-journey-carousel-mobile8',

  PaymentSuccessful = 'payment-successful',
  People1 = 'people1',
  People2 = 'people2',
  People3 = 'people3',
  ProgressYourProjectBuyer = 'progress-your-project-buyer',

  ProjectReportsBuyer = 'project_reports_buyer',
  ProjectReportsSeller = 'project_reports_seller',
  PublishCaseStudy = 'publish-case-study',

  QuickUpdatesBuyer = 'quick-updates-buyer',
  QuickUpdatesSeller = 'quick-updates-seller',

  RecordYourActivitiesBuyer = 'record-your-activities-buyer',
  RecordYourActivitiesSeller = 'record-your-activities-seller',
  ReduceDataMicromanagementBuyer = 'reduce-data-micromanagement-buyer',
  ReduceDataMicromanagementSeller = 'reduce-data-micromanagement-seller',
  ResolveIssuesBuyer = 'resolve-issues-buyer',
  ResolveIssuesSeller = 'resolve-issues-seller',
  ReviewYourCandidates = 'review-your-candidates',
  ReviewPitches = 'review-pitches',

  SaveYourTimeAndMoney = 'save-your-time-and-money',
  SecureProcessingBuyer = 'secure-processing-buyer',
  SecureProcessingSeller = 'secure-processing-seller',
  ShareYourProgressSeller = 'share-your-progress-seller',
  SignContractsBuyer = 'sign_contracts_buyer',
  SignContractsSeller = 'sign_contracts_seller',
  SignDigitallyBuyer = 'sign-digitally-buyer',
  SignDigitallySeller = 'sign-digitally-seller',
  SmartBudgetAllocationBuyer = 'smart-budget-allocation-buyer',
  SponaDashboard = 'spona-dashboard',
  SponaGives = 'spona-gives',
  SponaInNumbers = 'spona-in-numbers',
  SponaMakes = 'spona-makes',
  SponaMakesSubpage = 'spona-makes-subpage',
  SponaMakesTimedProjects = 'spona-makes-timed-projects',
  SponaMediaHero = 'spona-media-hero',
  StayInSyncSeller = 'stay-in-sync-seller',
  StandardizeYourProcessesBuyer = 'standardize-your-processes-buyer',
  StandardizeYourProcessesSeller = 'standardize-your-processes-seller',
  SubmitYourPitch = 'submit-your-pitch',
  SubmitYourProject = 'submit-your-project',

  TimedProjectSubageHero = 'timed-project-subpage-hero',
  TrackAndProcessPaymentsBuyer = 'track_and_process_payments_buyer',
  TrackAndProcessPaymentsSeller = 'track_and_process_payments_seller',
  TrackProjectHealthBuyer = 'track-project-health-buyer',
  TrackProjectHealthSeller = 'track-project-health-seller',

  TrackYourMetricsBuyer = 'track-your-metrics-buyer',
  TransparentEditingBuyer = 'transparent-editing-buyer',
  TransparentEditingSeller = 'transparent-editing-seller',
  TransparentPaymentsSeller = 'transparent-payments-seller',
  TransparentTransactionsBuyer = 'transparent-transactions-buyer',
  TransparentTransactionsSeller = 'transparent-transactions-seller',

  UK = 'uk',
  UKMobile = 'uk-mobile',
  USA = 'usa',
  USAMobile = 'usa-mobile',

  Zagreb = 'zagreb',
  ZagrebMobile = 'zagreb-mobile',
}

export const mapPublicPagesImagesUrls = new Map([
  [EnumPublicPagesImage.AboutOffice, 'about-office.png'],
  [EnumPublicPagesImage.AbaoutUsHero, 'about-us-hero.png'],
  [EnumPublicPagesImage.AccessibleSupportBuyer, 'accessible-support-buyer.svg'],
  [EnumPublicPagesImage.AccessibleSupportSeller, 'accessible-support-seller.svg'],
  [EnumPublicPagesImage.AccessAtAnyTimeBuyer, 'access-at-any-time-buyer.svg'],
  [EnumPublicPagesImage.AccessAtAnyTimeSeller, 'access-at-any-time-seller.svg'],
  [EnumPublicPagesImage.ArchiveHero, 'archive-hero.jpg'],
  [EnumPublicPagesImage.AutomaticNotificationsBuyer, 'automatic-notifications-buyer.svg'],

  [EnumPublicPagesImage.BuildYourProjectBig, 'build-your-projects-big.png'],
  [EnumPublicPagesImage.BuildYourProjectSmall, 'build-your-projects-small.png'],

  [EnumPublicPagesImage.CaseStudyArchiveHero, 'case-study-archive-hero.jpg'],
  [EnumPublicPagesImage.CaseStudyArchiveMacbook, 'case_study_archive_macbook.png'],
  [EnumPublicPagesImage.ClearCommunicationBuyer, 'clear-communication-buyer.svg'],
  [EnumPublicPagesImage.ClearCommunicationSeller, 'clear-communication-seller.svg'],
  [EnumPublicPagesImage.Communication, 'communication.png'],
  [EnumPublicPagesImage.CompleteMilestonesSeller, 'complete-milestones-seller.svg'],
  [EnumPublicPagesImage.ConfirmAccountBuyer, 'confirm-account-buyer.png'],
  [EnumPublicPagesImage.ConfirmAccountBuyerxl, 'confirm-account-buyerxl.png'],
  [EnumPublicPagesImage.ConfirmAccountSeller, 'confirm-account-seller.png'],
  [EnumPublicPagesImage.ConfirmAccountSellerxl, 'confirm-account-sellerxl.png'],
  [EnumPublicPagesImage.ConsolidateYourProjectsBuyer, 'consolidate-your-projects-buyer.svg'],
  [EnumPublicPagesImage.ConsolidateYourProjectsSeller, 'consolidate-your-projects-seller.svg'],
  [EnumPublicPagesImage.CreateProfile, 'create-profile.svg'],
  [EnumPublicPagesImage.CreateInRealTimeBuyer, 'create-in-real-time-buyer.svg'],
  [EnumPublicPagesImage.CreateInRealTimeSeller, 'create-in-real-time-seller.svg'],

  [EnumPublicPagesImage.DoItYourself, 'do-it-yourself.svg'],
  [EnumPublicPagesImage.DigitalContract, 'digital-contract.png'],
  [EnumPublicPagesImage.Dublin, 'dublin.jpg'],
  [EnumPublicPagesImage.DublinMobile, 'dublin-mobile.jpg'],

  [EnumPublicPagesImage.EasyResourcePlanningSeller, 'easy-resource-planning-seller.svg'],
  [EnumPublicPagesImage.EliminateLegalFeesBuyer, 'eliminate-legal-fees-buyer.svg'],
  [EnumPublicPagesImage.EliminateLegalFeesSeller, 'eliminate-legal-fees-seller.svg'],

  [EnumPublicPagesImage.GetFullControlBuyer, 'get-full-control-buyer.svg'],
  [EnumPublicPagesImage.GetInsightsBuyer, 'get-insights-buyer.svg'],
  [EnumPublicPagesImage.GetInsightsSeller, 'get-insights-seller.svg'],
  [EnumPublicPagesImage.GridForSellers, 'grid-for-sellers.png'],
  [EnumPublicPagesImage.GridProjectBenefits, 'grid-project-benefits.png'],

  [EnumPublicPagesImage.HireSeller, 'hire-a-seller.svg'],
  [EnumPublicPagesImage.HowToHireHero, 'how-to-hire-hero.png'],
  [EnumPublicPagesImage.HowToFindWorkHero, 'how-to-find-work-hero.png'],
  [EnumPublicPagesImage.Hungary, 'hungary.jpg'],
  [EnumPublicPagesImage.HungaryMobile, 'hungary-mobile.jpg'],

  [EnumPublicPagesImage.KeepInTouchBuyer, 'keep-in-touch-buyer.svg'],
  [EnumPublicPagesImage.KeepInTouchSeller, 'keep-in-touch-seller.svg'],

  [EnumPublicPagesImage.Laptop, 'laptop.png'],
  [EnumPublicPagesImage.LetSponaHandleIt, 'let-spona-handle-it.svg'],
  [EnumPublicPagesImage.LetSponaHandleItHero, 'let-spona-handle-it-hero.png'],
  [EnumPublicPagesImage.LetSponaHandleItSmall, 'let-spona-handle-it-small.png'],
  [EnumPublicPagesImage.ListYourRequirements, 'list-your-requirements.svg'],

  [EnumPublicPagesImage.MacbookSellerArchive, 'macbook-seller-archive.png'],
  [EnumPublicPagesImage.MakeDataDrivenDecisionsBuyer, 'make-data-driven-decisions-buyer.svg'],
  [EnumPublicPagesImage.MakeDataDrivenDecisionsSeller, 'make-data-driven-decisions-seller.svg'],
  [EnumPublicPagesImage.ManageMilestonesAndTasksBuyer, 'manage-milestones-and-tasks-buyer.svg'],
  [EnumPublicPagesImage.ManageMilestonesAndTasksSeller, 'manage-milestones-and-tasks-seller.svg'],
  [EnumPublicPagesImage.MilestonesAndTasksHero, 'milestones-and-tasks-hero.png'],
  [EnumPublicPagesImage.MonitorYourKPIs, 'monitor-your-kpis.png'],

  [EnumPublicPagesImage.NewPasswordChangeSection, 'new-password-side-image.jpg'],

  [EnumPublicPagesImage.Office, 'office.png'],
  [EnumPublicPagesImage.Office2, 'office2.png'],
  [EnumPublicPagesImage.Office3, 'office3.png'],
  [EnumPublicPagesImage.Office4, 'office4.png'],
  [EnumPublicPagesImage.Office5, 'office5.png'],
  [EnumPublicPagesImage.OnePlatformBuyer, 'one-platform-buyer.svg'],
  [EnumPublicPagesImage.OnePlatformSeller, 'one-platform-seller.svg'],
  [EnumPublicPagesImage.OurJourneyCarousel, 'our-journey-carousel.png'],
  [EnumPublicPagesImage.OurJourneyCarousel2, 'our-journey-carousel2.png'],
  [EnumPublicPagesImage.OurJourneyCarousel3, 'our-journey-carousel3.png'],
  [EnumPublicPagesImage.OurJourneyCarousel4, 'our-journey-carousel4.png'],
  [EnumPublicPagesImage.OurJourneyCarousel5, 'our-journey-carousel5.png'],
  [EnumPublicPagesImage.OurJourneyCarousel6, 'our-journey-carousel6.png'],
  [EnumPublicPagesImage.OurJourneyCarousel7, 'our-journey-carousel7.png'],
  [EnumPublicPagesImage.OurJourneyCarousel8, 'our-journey-carousel8.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile, 'our-journey-carousel-mobile.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile2, 'our-journey-carousel-mobile2.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile3, 'our-journey-carousel-mobile3.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile4, 'our-journey-carousel-mobile4.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile5, 'our-journey-carousel-mobile5.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile6, 'our-journey-carousel-mobile6.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile7, 'our-journey-carousel-mobile7.png'],
  [EnumPublicPagesImage.OurJourneyCarouselMobile8, 'our-journey-carousel-mobile8.png'],

  [EnumPublicPagesImage.PaymentSuccessful, 'payment-successful.png'],
  [EnumPublicPagesImage.People1, 'people1.png'],
  [EnumPublicPagesImage.People2, 'people2.png'],
  [EnumPublicPagesImage.People3, 'people3.png'],
  [EnumPublicPagesImage.ProgressYourProjectBuyer, 'progress-your-project-buyer.svg'],
  [EnumPublicPagesImage.ProjectReportsBuyer, 'project-reports-buyer.svg'],
  [EnumPublicPagesImage.ProjectReportsSeller, 'project-reports-seller.svg'],
  [EnumPublicPagesImage.PublishCaseStudy, 'publish-case-study.svg'],

  [EnumPublicPagesImage.QuickUpdatesBuyer, 'quick-updates-buyer.svg'],
  [EnumPublicPagesImage.QuickUpdatesSeller, 'quick-updates-seller.svg'],

  [EnumPublicPagesImage.RecordYourActivitiesBuyer, 'record-your-activities-buyer.svg'],
  [EnumPublicPagesImage.RecordYourActivitiesSeller, 'record-your-activities-seller.svg'],
  [EnumPublicPagesImage.ReduceDataMicromanagementBuyer, 'reduce-data-micromanagement-buyer.svg'],
  [EnumPublicPagesImage.ReduceDataMicromanagementSeller, 'reduce-data-micromanagement-seller.svg'],
  [EnumPublicPagesImage.ResolveIssuesBuyer, 'resolve-issues-buyer.svg'],
  [EnumPublicPagesImage.ResolveIssuesSeller, 'resolve-issues-seller.svg'],
  [EnumPublicPagesImage.ReviewPitches, 'review-pitches.svg'],
  [EnumPublicPagesImage.ReviewYourCandidates, 'review-your-candidates.svg'],

  [EnumPublicPagesImage.SaveYourTimeAndMoney, 'save-your-time-and-money.svg'],
  [EnumPublicPagesImage.SecureProcessingBuyer, 'secure-processing-buyer.svg'],
  [EnumPublicPagesImage.SecureProcessingSeller, 'secure-processing-seller.svg'],
  [EnumPublicPagesImage.ShareYourProgressSeller, 'share-your-progress-seller.svg'],
  [EnumPublicPagesImage.SignContractsBuyer, 'sign-contracts-buyer.svg'],
  [EnumPublicPagesImage.SignContractsSeller, 'sign-contracts-seller.svg'],
  [EnumPublicPagesImage.SignDigitallyBuyer, 'sign-digitally-buyer.svg'],
  [EnumPublicPagesImage.SignDigitallySeller, 'sign-digitally-seller.svg'],
  [EnumPublicPagesImage.SmartBudgetAllocationBuyer, 'smart-budget-allocation-buyer.svg'],
  [EnumPublicPagesImage.SponaDashboard, 'login-carousel-img1.png'],
  [EnumPublicPagesImage.SponaGives, 'spona-gives.png'],
  [EnumPublicPagesImage.SponaInNumbers, 'spona-in-numbers.svg'],
  [EnumPublicPagesImage.SponaMakes, 'spona-makes.png'],
  [EnumPublicPagesImage.SponaMakesTimedProjects, 'spona-makes-timed-projects.png'],
  [EnumPublicPagesImage.SponaMakesSubpage, 'spona-makes-subpage.png'],
  [EnumPublicPagesImage.SponaMediaHero, 'spona-media-hero.png'],
  [EnumPublicPagesImage.StandardizeYourProcessesBuyer, 'standardize-your-processes-buyer.svg'],
  [EnumPublicPagesImage.StandardizeYourProcessesSeller, 'standardize-your-processes-seller.svg'],
  [EnumPublicPagesImage.StayInSyncSeller, 'stay-in-sync-seller.svg'],
  [EnumPublicPagesImage.SubmitYourPitch, 'submit-your-pitch.svg'],
  [EnumPublicPagesImage.SubmitYourProject, 'submit-your-project.svg'],

  [EnumPublicPagesImage.TimedProjectSubageHero, 'timed-project-subpage-hero.png'],
  [EnumPublicPagesImage.TrackAndProcessPaymentsBuyer, 'track-and-process-payments-buyer.svg'],
  [EnumPublicPagesImage.TrackAndProcessPaymentsSeller, 'track-and-process-payments-seller.svg'],

  [EnumPublicPagesImage.TrackProjectHealthBuyer, 'track-project-health-buyer.svg'],
  [EnumPublicPagesImage.TrackProjectHealthSeller, 'track-project-health-seller.svg'],
  [EnumPublicPagesImage.TrackYourMetricsBuyer, 'track-your-metrics-buyer.svg'],
  [EnumPublicPagesImage.TransparentEditingBuyer, 'transparent-editing-buyer.svg'],
  [EnumPublicPagesImage.TransparentEditingSeller, 'transparent-editing-seller.svg'],
  [EnumPublicPagesImage.TransparentPaymentsSeller, 'transparent-payments-seller.svg'],
  [EnumPublicPagesImage.TransparentTransactionsBuyer, 'transparent-transactions-buyer.svg'],
  [EnumPublicPagesImage.TransparentTransactionsSeller, 'transparent-transactions-seller.svg'],

  [EnumPublicPagesImage.UK, 'uk.jpg'],
  [EnumPublicPagesImage.UKMobile, 'uk-mobile.jpg'],
  [EnumPublicPagesImage.USA, 'usa.jpg'],
  [EnumPublicPagesImage.USAMobile, 'usa-mobile.jpg'],

  [EnumPublicPagesImage.Zagreb, 'zagreb.jpg'],
  [EnumPublicPagesImage.ZagrebMobile, 'zagreb-mobile.jpg'],
]);
