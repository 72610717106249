import { Stack, Typography, Box } from '@mui/material';
import AccordionGroups from '../../../components/public-screen-components/accordion-groups/AccordionGroups';
import { forSellersArray } from '../utils';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

const ForSellersSection = () => (
  <Stack
    direction="row"
    spacing={{ md: 8 }}
    my={{ xs: 10, lg: 10 }}
    sx={{ ...LAYOUT_DEFAULTS }}
    justifyContent="space-between"
    alignItems="center"
  >
    <Stack
      justifyContent="start"
      alignItems="start"
      spacing={{ xs: 3, lg: 6 }}
      sx={{ width: { xs: '100%', lg: '50%' } }}
    >
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        For Sellers
      </Typography>

      <Box
        src={getPublicImageUrl(EnumPublicPagesImage.GridForSellers)}
        component="img"
        sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}
        alt="Team lead holding a marketing meeting"
      />

      <AccordionGroups
        group={forSellersArray}
        image={getPublicImageUrl(EnumPublicPagesImage.GridForSellers)}
      />
    </Stack>

    <Box
      src={getPublicImageUrl(EnumPublicPagesImage.GridForSellers)}
      component="img"
      sx={{ width: '37%', display: { xs: 'none', md: 'block' } }}
      alt="Team lead holding a marketing meeting"
    />
  </Stack>
);

export default ForSellersSection;
